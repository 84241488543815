import { Component, OnInit, Inject, Input } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TrackingService } from '../../services/tracking.service'
import { AuthService } from '../../services/auth.service'
import { TranslateService } from '@ngx-translate/core'
import { HttpClient } from '@angular/common/http'
import { DOCUMENT } from '@angular/common'
import { ApiService } from '../../services/api.service'
import { Router } from '@angular/router'
import { RedirectService } from '../../services/redirect.service'
import { ToastrService } from 'ngx-toastr'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import disableScroll from 'disable-scroll'

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private trackingService: TrackingService,
    private router: Router,
    private apiService: ApiService,
    private translate: TranslateService,
    private redirectService: RedirectService,
    private authService: AuthService,
    private toastr: ToastrService,
    private readonly formBuilder: FormBuilder
  ) {
    this.hash = this.authService.getHash()
  }

  @Input() set data2popups(value: any[]) {
    this._data2popups = value
    this.estado = this._data2popups.estado
    const fechaProntoPago = this._data2popups.fechaProntoPago.date
    const fechaHoraServidor = this._data2popups.fechaHoraServidor.date
    const fechaFinEnlace = this._data2popups.fechaFinEnlace.date
    const fechaFinEnlacef = new Date(fechaFinEnlace.replace(' ', 'T'))
    const fechaProntoPagof = new Date(fechaProntoPago.replace(' ', 'T'))
    const fechaHoraServidorf = new Date(fechaHoraServidor.replace(' ', 'T'))
    if (this._data2popups.tipoEnlace === '2COM') {
      this.isprontopago =
        fechaHoraServidorf.getTime() < fechaProntoPagof.getTime()
    }
    if (
      fechaProntoPagof.getHours() === 0 &&
      fechaProntoPagof.getMinutes() === 0
    ) {
      this.fechaProntoPagoString =
        fechaProntoPagof.getDate().toString() +
        '-' +
        (fechaProntoPagof.getMonth() + 1).toString() +
        '-' +
        fechaProntoPagof.getFullYear().toString()
    } else {
      this.fechaProntoPagoString =
        fechaProntoPagof.getDate().toString() +
        '-' +
        (fechaProntoPagof.getMonth() + 1).toString() +
        '-' +
        fechaProntoPagof.getFullYear().toString() +
        ' ' +
        PopupComponent.addTimeZero(fechaProntoPagof.getHours()) +
        ':' +
        PopupComponent.addTimeZero(fechaProntoPagof.getMinutes())
    }
    if (
      fechaFinEnlacef.getHours() === 0 &&
      fechaFinEnlacef.getMinutes() === 0
    ) {
      this.fechaFinEnlaceString =
        fechaFinEnlacef.getDate().toString() +
        '-' +
        (fechaFinEnlacef.getMonth() + 1).toString() +
        '-' +
        fechaFinEnlacef.getFullYear().toString()
    } else {
      this.fechaFinEnlaceString =
        fechaFinEnlacef.getDate().toString() +
        '-' +
        (fechaFinEnlacef.getMonth() + 1).toString() +
        '-' +
        fechaFinEnlacef.getFullYear().toString() +
        ' ' +
        PopupComponent.addTimeZero(fechaFinEnlacef.getHours()) +
        ':' +
        PopupComponent.addTimeZero(fechaFinEnlacef.getMinutes())
    }
    if (
      this._data2popups.importeAPagar < this._data2popups.importeDenuncia ||
      this._data2popups.importePagado < this._data2popups.importeDenuncia ||
      this.estado === 'cerrado'
    ) {
      this.hasDiscount = true
    }
  }

  private readonly hash: string
  public tpvData: any
  public _data2popups: any
  public isprontopago = true
  public fechaFinEnlaceString: string
  public fechaProntoPagoString: string
  public estado: string
  public formGroup: FormGroup
  public hasDiscount = false

  private static addTimeZero(i): string {
    if (i < 10) {
      i = '0' + i
    }
    return i
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      cb: [false, Validators.requiredTrue]
    })
  }

  popup1(modal1, event) {
    disableScroll.on()
    this.modalService.dismissAll()
    this.modalService.open(modal1, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true,
      windowClass: 'animated fadeIn'
    })
    this.myEvent(event)
  }

  popup2(modal2, event) {
    this.modalService.dismissAll()
    this.modalService.open(modal2, {
      size: 'lg',
      centered: true,
      windowClass: 'animated fadeIn'
    })
    this.myEvent(event, 'LAW')
  }

  popup3(modal3, event) {
    this.modalService.dismissAll()
    this.modalService.open(modal3, {
      size: 'lg',
      centered: true,
      windowClass: 'animated fadeIn'
    })
    this.myEvent(event, 'LAW')
  }

  popuprecurso(modalrecurso, event) {
    this.modalService.dismissAll()
    this.modalService.open(modalrecurso, {
      size: 'lg',
      centered: true,
      windowClass: 'animated fadeIn'
    })
    this.myEvent(event)
  }

  legalOption(event) {
    this.modalService.dismissAll()
    this.myEvent(event, 'LAW')
  }

  initPay(modalRedirect) {
    this.apiService.initPayProcess(`${this.hash}/iniciapago`).subscribe(
      (response: any) => {
        this.modalService.dismissAll()
        this.modalService.open(modalRedirect, {
          centered: true,
          windowClass: 'animated fadeIn'
        })
        this.tpvData = response
        const params = {
          Ds_SignatureVersion: this.tpvData.Ds_SignatureVersion,
          Ds_MerchantParameters: this.tpvData.Ds_MerchantParameters,
          Ds_Signature: this.tpvData.Ds_Signature
        }
        this.redirectService.post(params, this.tpvData.URL)
      },
      (error) => {
        this.errorEvent(error.status, error.message)
        if (error.status === 409) {
          this.modalService.dismissAll()
          const errMsg: any = this.translate.instant('ErrorsMessages.InitPay')
          this.toastr.warning(errMsg)
          return false
        } else {
          this.modalService.dismissAll()
          this.authService.logout()
          this.router
            .navigate([`/app/login/faw/${this.hash}`], {
              queryParams: { error: error.status }
            })
            .then((r) => console.log(r))
        }
      }
    )
  }

  myEvent(event, type = 'SHOW_POPUP') {
    if (
      this.trackingService.getPermissionTracking() === 'all' ||
      this.trackingService.getPermissionTracking() === 'statistics'
    ) {
      this.trackingService.setHash(this.hash)
      this.trackingService.actionEvent(event, type)
    }
  }

  errorEvent(status, message) {
    if (
      this.trackingService.getPermissionTracking() === 'all' ||
      this.trackingService.getPermissionTracking() === 'statistics'
    ) {
      this.trackingService.setHash(this.hash)
      this.trackingService.errorEvent(status, message)
    }
  }

  enableScroll() {
    disableScroll.off()
  }
}
