import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router'
import { AuthService } from '../auth.service'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root'
})
export class VerifyTokenGuard implements CanActivate {
  private hash: string

  constructor(
    public authService: AuthService,
    public router: Router,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
    this.hash = this.authService.getHash()
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const token = this.authService.getToken()
    if (token && token !== '') {
      const payload = JSON.parse(atob(token.split('.')[1]))
      const expired = this.isExpired(payload.exp)
      if (expired) {
        this.router.navigate([`/app/login/faw/${this.hash}`], {
          queryParams: { returnUrl: state.url }
        })
        const expiredErrMsg: any = this.translate.instant(
          'LoginPage.ExpiredSession'
        )
        this.toastr.error(expiredErrMsg)
        return false
      }
      return true
    } else {
      const url_split = state.url.split('/')
      this.hash = url_split[url_split.length - 1]
      this.router.navigate([`/app/login/faw/${this.hash}`], {
        queryParams: { returnUrl: state.url }
      })
      const expiredErrMsg: any = this.translate.instant(
        'LoginPage.ExpiredSession'
      )
      this.toastr.error(expiredErrMsg)
      return false
    }
  }

  isExpired(dateExp: number) {
    const now = new Date().getTime() / 1000
    return dateExp < now
  }
}
